// i18next-extract-mark-ns-start boats_list
import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BoatsHeaderSection from "../components/boats/BoatsHeaderSection"
import BoatsListingSection from "../components/boats/BoatsListingSection"

const BoatsPage = (props) => {

  //props.data.allBoat.nodes

  return (
    <Layout>
      <Seo title="Flota" />
      <BoatsHeaderSection />
      <BoatsListingSection boats={props.data.boats.types} />
    </Layout>
  )
}

export default BoatsPage

export const query = graphql`
  query($language: String!) {
    boats: allBoat {
      types: group(field: type) {
        totalCount
        type: fieldValue
        boats: nodes {
          name
          capacity
          image {
              childImageSharp {
                  gatsbyImageData(
                      layout: FULL_WIDTH
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: BLURRED
                  )
              }
          }
          pagePath: gatsbyPath(filePath: "/flota/{Boat.name}")
        }
      }
    }
    locales: allLocale(filter: {ns:{in: ["navigation","footbar","boat_types","boats_list","boats_list_card"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;