// i18next-extract-mark-ns-start boats_list_card
import * as React from "react";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImage } from "gatsby-plugin-image";
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

const BoatCard = ({ boat }: { boat: { name: string, capacity: number, pagePath: string, image?: IGatsbyImageData } }) => {

    const { navigate } = useI18next();

    const image = getImage(boat.image);

    const imageOverlayHover = "bg-dark";
    const imageOverlayIdle = "bg-black";

    const [imageOverlay, setImageOverlay] = React.useState(imageOverlayIdle);

    const goToBoatPage = () => navigate(boat.pagePath);

    const hoverStyleToggle = () => {
        setImageOverlay((imageOverlay == imageOverlayIdle) ? imageOverlayHover : imageOverlayIdle)
    }

    return (
        <div role="button" onMouseEnter={hoverStyleToggle} onMouseLeave={hoverStyleToggle} onClick={goToBoatPage} className="card card-cover overflow-hidden text-white bg-dark rounded-5 shadow-lg position-relative" style={{ height: "250px" }}>
            <div
                className="position-absolute container-fluid h-100 p-0"
                style={{ height: "250px" }}
            >
                <GatsbyImage
                    image={image}
                    alt=""
                    style={{ height: "250px" }}
                />
            </div>
            <div className={`position-absolute container-fluid h-100 p-0 bg-opacity-75 ${imageOverlay}`} ></div>
            <div className="position-absolute bottom-0 start-0 ps-4 pb-2 text-white text-shadow-1 " >

                <h2 className="display-6 lh-1 fw-bold">{boat.name}</h2>
                <ul className="d-flex list-unstyled mt-auto">
                    <li className="d-flex align-items-center me-3">
                        <small><FontAwesomeIcon icon={faPeopleGroup} className="me-2" />{boat.capacity} <Trans>os.</Trans></small>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default BoatCard;