import * as React from "react";

const BoatsGroupsList = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="accordion" id="boatsList">
            {children}
        </div>
    )
}

export default BoatsGroupsList;