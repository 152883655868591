// i18next-extract-mark-ns-start boats_list
import * as React from "react"
import PageSection from "../page-section"
import { Trans } from "gatsby-plugin-react-i18next"

const BoatsHeaderSection = () => {

    return (
        <PageSection>
            <h1 className="pb-3"><Trans>Nasza flota</Trans></h1>
            <p><Trans>W naszej ofercie posiadamy 43 jednoski pływające. Są to statki różnej wielkości od małych kilkuosobowych łodzi i motorówek po kilkuset osobowe duże statki pasażerskie. W tym największy pasażerski statek rzeczny w Polsce!</Trans></p>
        </PageSection>
    )
}

export default BoatsHeaderSection