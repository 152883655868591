import * as React from "react";

const BoatsGroupItem = ({ children, title, id, quantity }: { children: React.ReactNode, title: string, id: string, quantity: number }) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`${id}-heading`}>
                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded="true" aria-controls="collapseOne">
                    {title} <span className="position-absolute top-50
                 start-100 translate-middle badge bg-dark">{quantity}</span>
                </button>
            </h2>
            <div id={id} className="accordion-collapse collapse" aria-labelledby={`${id}-heading`} data-bs-parent="#boatsList">
                <div className="accordion-body bg-white">
                    <div className="d-grid gap-3">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoatsGroupItem;