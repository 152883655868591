import * as React from "react"
import PageSection from "../page-section"
import BoatCard from "./BoatCard"
import BoatsGroupItem from "./BoatsGroupItem"
import BoatsGroupsList from "./BoatsGroupsList"
import slugify from 'slugify';
import { IGatsbyImageData } from "gatsby-plugin-image"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const BoatsListingSection = ({ boats }: { boats: [{ totalCount: number, type: string, boats: [{ id?: string, name: string, capacity: number, pagePath: string, image?: IGatsbyImageData }] }] }) => {

    const { t } = useTranslation();

    return (
        <PageSection className="bg-white text-black">
            <BoatsGroupsList>
                {
                    boats.map((boatGroup, group_index) =>
                        <BoatsGroupItem key={`boat-group-${group_index}`} id={slugify(boatGroup.type)} title={t(boatGroup.type) /* i18next-extract-disable-line */} quantity={boatGroup.totalCount} >
                            {boatGroup.boats.map((boat) => <BoatCard key={boat.id ?? slugify(boat.name)} boat={boat} />)}
                        </BoatsGroupItem>
                    )
                }
            </BoatsGroupsList>
        </PageSection>
    )
}

export default BoatsListingSection